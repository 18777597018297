import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import ForthcomingCourses from '../components/ForthcomingCourses';
import ReviewsSection from '../components/ReviewsSection';
import SubscriptionForm from '../components/SubscriptionForm';

import IconArrowRight from '../assets/images/svg/arrow-right.inline.svg';
import IconArrowRightRed from '../assets/images/svg/arrow-right-red.inline.svg';
import img1 from '../assets/images/home-page/partners/Trinity logo no background.png';
import img2 from '../assets/images/home-page/partners/A-logo.png';
import img3 from '../assets/images/home-page/partners/I-logo.png';
import img4 from '../assets/images/home-page/partners/CSLB-logo-400x126.png';
import img5 from '../assets/images/home-page/partners/Languages4Life logo.png';
import imgBenefit1 from '../assets/images/home-page/help-s/Internationally Recognized Qualification.svg';
import imgBenefit2 from '../assets/images/home-page/help-s/Classroom Confidence Building.svg'; //
import imgBenefit3 from '../assets/images/home-page/help-s/Post-Grad Job Prospects.svg';
import imgBenefit4 from '../assets/images/home-page/help-s/Varied Learning Styles.svg';
import imgBenefit5 from '../assets/images/home-page/help-s/Small Group Sizes .svg';
import imgDots from '../assets/images/home-page/Dots.png';
import imgDots2 from '../assets/images/home-page/Dots 2.svg';
import imgPhoto_r from '../assets/images/home-page/Photo_r.png';
import imgPhoto_l from '../assets/images/home-page/Photo_l.png';
import imgAccomodation from '../assets/images/Accommodation.svg';
import imgStudentVisas from '../assets/images/Student visas.svg';
import imgCareersAdvice from '../assets/images/Careers advice.svg';
// import fileTEFLIberiaBrochure from '../assets/files/TEFL Iberia brochure web.pdf';

import '../assets/styles/pages/home-page.scss';

const IndexPage = ({ data }) => {
  const allPrismicArticle = data.allPrismicArticle.edges;
  const prismicHomePage = data.prismicHomePage.data;

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        {/* <!-- Meta Pixel Code --> */}
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1389098578493723');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1389098578493723&ev=PageView&noscript=1"
            />`}
        </noscript>
        {/* <!-- End Meta Pixel Code --> (edited)  */}
      </Helmet>

      <Layout
        title="The Best TEFL Courses in Barcelona & Seville | Trinity CertTESOL"
        description="The best TEFL courses in Barcelona, Spain, from TEFL Iberia. Apply online. Our 160-hour TEFL course is only €1500 with an early-bird discount!"
      >
        <div className="home-page">
          <section className="c-first-section c-first-section--lg">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-9 col-xl-7">
                  <h1 className="c-first-section__title">
                    Train as an English teacher in Spain and Unlock a World of Possibilities
                  </h1>
                  <p className="c-first-section__description">
                    Enroll with TEFL Iberia and start your training in Barcelona or Seville.
                  </p>
                  <div className="c-first-section__btns">
                    <a href="/apply/" className="c-btn c-btn--red">
                      Apply Now
                      <IconArrowRight />
                    </a>
                    {/* <Link to="/covid/" className="c-btn c-btn--custom">
                    Coronavirus FAQs
                  </Link> */}
                    {/* <Link href="/covid/" className="c-btn c-btn--custom">
                    FAQ: Coronavirus & TEFLs
                  </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="second-section">
            <div className="container">
              <div className="second-section__wrapper">
                <div className="c-title-13  partner-title">TEFL IBERIA IS AFFILIATED WITH</div>
                <div className="partners">
                  <div className="partner">
                    <img src={img1} alt="" />
                  </div>
                  <div className="partner">
                    <img src={img2} alt="" />
                  </div>
                  <div className="partner">
                    <img src={img3} alt="" />
                  </div>
                  <div className="partner">
                    <img src={img4} alt="" />
                  </div>
                  <div className="partner">
                    <img src={img5} alt="" />
                  </div>
                </div>
              </div>

              {/* CARDS */}
              <ForthcomingCourses
                specialHomepageOutlook
                specialHomepageCardsData={prismicHomePage?.forthcoming_courses?.slice(0, 3)}
                title={{ id: 'forthcomingCourses.title' }}
              />
              {/* CARDS */}
            </div>
          </section>
          <section className="cta-section c-section-py">
            <div className="container">
              <div className="row">
                <div className="col">
                  <SubscriptionForm />
                </div>
              </div>
            </div>
          </section>
          <section className="third-section">
            <div className="third-section__inner">
              <div className="wrapper container">
                <div className="block_l">
                  <h3 className="c-title-34">TEFL Iberia Helps Students Thrive. Here's How:</h3>
                </div>
                <div className="block_r">
                  <img src={imgDots} alt="" className="block_r__dots" />
                  <div className="benefit">
                    <img src={imgBenefit1} alt="" />
                    <div className="benefit__text">
                      <h4 className="c-title-18">Internationally Recognized Qualification</h4>
                      <p className="c-text-14">
                        Barcelona? Bangkok? Brazil? Trinity CertTESOL is accepted across the globe.
                      </p>
                    </div>
                  </div>

                  <div className="benefit">
                    <img src={imgBenefit2} alt="" />
                    <div className="benefit__text">
                      <h4 className="c-title-18">Classroom Confidence Building</h4>
                      <p className="c-text-14">
                        Because teaching English involves more than just mastering the material.
                      </p>
                    </div>
                  </div>

                  <div className="benefit">
                    <img src={imgBenefit3} alt="" />
                    <div className="benefit__text">
                      <h4 className="c-title-18">Post-Grad Job Prospects</h4>
                      <p className="c-text-14">
                        We work hard to ensure that all our students find relevant work after
                        graduation.
                      </p>
                    </div>
                  </div>

                  <div className="benefit">
                    <img src={imgBenefit4} alt="" />
                    <div className="benefit__text">
                      <h4 className="c-title-18">Varied Learning Styles</h4>
                      <p className="c-text-14">
                        Variety is the spice of life and a key ingredient of our innovative lesson
                        plans.
                      </p>
                    </div>
                  </div>

                  <div className="benefit">
                    <img src={imgBenefit5} alt="" />
                    <div className="benefit__text">
                      <h4 className="c-title-18">Small Group Sizes </h4>
                      <p className="c-text-14">
                        Our classes max out at 12, giving everybody access to an instructor.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="fourth-section c-section-py">
            <ReviewsSection title={{ id: 'graduates.title' }} />
          </section>

          <section className="fifth-section">
            <div className="wrapper c-section-py">
              <div className="container block_l">
                <div className="row no-gutters">
                  <div className="col-xl-12 offset-xl-2">
                    <div className="content">
                      <h2 className="c-title-34">
                        We've Helped Thousands of Students Learn to Teach English.
                        <span> Are You Next?</span>
                      </h2>
                      <p className="c-text-18">
                        Students come from all over the world to train with TEFL Iberia, eager to
                        experience the adventure of living in Spain, the benefit of learning to
                        teach, and the ability to make a difference teaching English for many years
                        to come.
                      </p>
                      <div className="block_l__bottom">
                        <div className="item">
                          <h2 className="c-title-42">1000+</h2>
                          <p className="c-text-14">Student success stories since 2012</p>
                        </div>
                        <div className="item">
                          <h2 className="c-title-42">60+</h2>
                          <p className="c-text-14">Nationalities represented by our alumni</p>
                        </div>
                        <div className="item">
                          <h2 className="c-title-42">20%</h2>
                          <p className="c-text-14">Of our students are not native speakers</p>
                        </div>
                      </div>
                    </div>
                    <div className="block_r">
                      <div className="block_r__img">
                        <img src={imgPhoto_r} alt="imgPhoto_r" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sixth-section c-section-py">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <h2 className="c-title-38 text-center mx-auto">
                      Got Questions? Ask Us Anything
                    </h2>
                  </div>
                  <div className="c-btn-wrapper">
                    <Link to="/contact/" className="c-btn c-btn--red">
                      Contact Us
                      <IconArrowRight />
                    </Link>
                    <Link
                      to={'/tefl-iberia-brochure'}
                      className="c-btn c-btn--red-border"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      Download Brochure
                      <IconArrowRightRed />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="seventh-section c-section-py">
            <div className="container">
              <h2 className="c-title-34">You're Always in Good Hands!</h2>
              <p className="c-text-18">
                TEFL Iberia is more than just a training school. Our amazing student support team
                helps with accommodation, visas, bureaucracy and more.
              </p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-1">
                  <div className="img_l">
                    <img src={imgPhoto_l} alt="imgPhoto_l" />
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-12 offseted">
                  <div className="item">
                    <img src={imgAccomodation} alt="imgAccomodation" />
                    <h3 className="c-title-22">Accommodation</h3>
                    <p className="c-text-14">
                      We want your time in Spain to be memorable inside ​and​ outside of the
                      classroom. That's why we place you in accommodation with other students on
                      your course or recent graduates.
                    </p>
                    <div className="item__btns">
                      <Link to="/accommodation/" className="c-btn c-btn--white">
                        Find out more
                        <IconArrowRightRed />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-5 col-md-6">
                  <div className="item">
                    <img src={imgStudentVisas} alt="imgAccomodation" />
                    <h3 className="c-title-22">Student visas</h3>
                    <p className="c-text-14">
                      Need help understanding your student visa requirements in Spain and navigating
                      the application process? The TEFL Iberia team is always available to lend a
                      qualified hand.
                    </p>
                    <div className="item__btns">
                      <Link to="/working-as-a-teacher/student-visas" className="c-btn c-btn--white">
                        Find out more
                        <IconArrowRightRed />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="item">
                    <img src={imgCareersAdvice} alt="imgAccomodation" />
                    <h3 className="c-title-22">Careers advice</h3>
                    <p className="c-text-14">
                      Find your dream job with TEFL Iberia. Our tutors have over 30 years of
                      combined international experience and built up a world-class network of
                      contacts.
                    </p>
                    <div className="item__btns">
                      <Link
                        to="/working-as-a-teacher/careers-service"
                        className="c-btn c-btn--white"
                      >
                        Find out more
                        <IconArrowRightRed />
                      </Link>
                    </div>
                  </div>
                  <img className="dots" src={imgDots2} alt="imgDots2" />
                </div>
              </div>
            </div>
          </section>

          <section className="eight-section">
            <div className="container">
              <div className="top">
                <h2 className="c-title-34">Latest From our Blog</h2>
                <Link to="/blog/" className="c-btn c-btn--white">
                  See all posts
                  <IconArrowRightRed />
                </Link>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  {allPrismicArticle.slice(0, 1).map(({ node }) => (
                    <Link to={`/blog/${node.uid}`} className="post post--md" key={node.uid}>
                      <div className="post--md__img">
                        {node?.data?.image && (
                          <img src={node.data.image.url} alt={node.data?.title?.text} />
                        )}
                      </div>
                      <div className="post--md__content">
                        <h3 className="c-title-20">{node.data?.title?.text}</h3>
                        <p className="c-text-14">{node.data?.content?.text.slice(0, 275)}...</p>
                        <div className="c-btn c-btn--white">
                          Read more
                          <IconArrowRightRed />
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
                <div className="col-lg-7 d-flex">
                  {allPrismicArticle.slice(1).map(({ node }) => (
                    <Link to={`/blog/${node.uid}`} className="post post--sm" key={node.uid}>
                      <div className="post--sm post--sm__img">
                        {node?.data?.image && (
                          <img src={node.data.image.url} alt={node.data?.title?.text} />
                        )}
                      </div>
                      <div className="post--sm__content">
                        <h3 className="c-title-20">{node.data?.title?.text}</h3>
                        <p className="c-text-14">{node.data?.content?.text.slice(0, 125)}...</p>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="nineth-section c-section-py">
            <div className="container">
              <h2 className="c-title-38">
                Ready to Take the First Steps Towards
                <span className="colored no-link"> Your New Career?</span>
              </h2>
              <p className="c-text-18">
                It's now easier than ever to become an international English teacher. Apply now
                start working towards your own dream job today.
              </p>
              <Link to="/apply/" className="c-btn c-btn--red">
                Apply now
                <IconArrowRight />
              </Link>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    allPrismicArticle(limit: 4, sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            content {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
    prismicHomePage {
      data {
        forthcoming_courses {
          title {
            text
          }
          description
          date(formatString: "D MMMM YYYY")
          image {
            url
          }
          link
        }
      }
    }
  }
`;

export default IndexPage;
